.Dialog-overlay {
    background: rgba(0, 0, 0, 0.8);
    display: grid;
    place-items: center;
}

.DialogBody {
    background-color: black;
    border-radius: 32px;
    border: 4px solid #5C9DFF;

    box-shadow: 0px 4px 32px #5C9DFF;

    width: 75%;
    height: 75%;

    display: grid;
    justify-content: center;
    align-content: center;
    justify-items: center;

    font-family: 'League Spartan', sans-serif;
    color: #E3E3E3;

    gap: 14px;
}

.DialogBody__logo {
    height: 170px;
}

.DialogBody__subheader {
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
}

.DialogBody__title {
    font-size: 40px;
}

.DialogBody__progress {
    width: 220px;
}

.DialogBody__button {
    background-color: #5B9BFF;
    color: white;

    border-radius: 8px;
    border: none;

    padding: 16px;
    width: 320px;

    font-size: 26px;
    font-family: 'League Spartan', sans-serif;
    font-weight: 700;
}


.DialogBody__button:hover:enabled {
    cursor: pointer;
}

.DialogBody__button:disabled {
    opacity: 0.3;
}

.DialogBody__cancel {
    background: none;
    border: none;

    color: #E3E3E3;
    opacity: 60%;
    font-size: 18px;
    font-family: 'League Spartan', sans-serif;
    font-weight: 700;
    text-decoration: underline;
}

.DialogBody__cancel:hover {
    cursor: pointer;
}
