.DownloadButton {
  height: 62px;
  width: 420px;
  font-size: 16pt;
  font-family: 'League Spartan';
  padding: 16px;
  margin-top: 14px;
  background: #f1ede3;
  border-radius: 8px;
  color: black;
  border: none;
}

.DownloadButton:hover:enabled {
  cursor: pointer;
}

.DownloadButton:disabled {
  opacity: 0.3;
}

.DownloadButton.extended {
  height: 124px;
  transition: 500ms;
}

.DownloadButton.extended.success {
  opacity: 1;
  background: #c7f3e0;
  color: #045131;
}

.DownloadButton.extended.error {
  opacity: 1;
  background: #f7e5e5;
  color: #a80e0e;
}
