.animation-selector-container {
  width: 90%;
  margin: auto;
  padding: 12px;
  padding-top: 4px;
  background-color: black;
  border-radius: 10px;
}

.speed-selector-options {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
}

.selector-header {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  color: #ffffff;
  opacity: 0.6;
  padding-top: 16px;
  padding-left: 6px;
}

.animation-selector-options {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.animation-option-not-selected,
.animation-option-selected {
  border-radius: 4px;
  cursor: pointer;

  align-items: center;
  justify-content: center;
  display: flex;

  color: #e3e3e3;
}

.animation-option-not-selected {
  opacity: 0.3;
  border: 2px solid #ffffff;
}

.animation-option-selected {
  border: 2px solid #5b9bff;
  box-shadow: 0px 0px 11px 3px #5b9bff;
}

.animation-type-option {
  width: 120px;
  height: 58px;
  margin: 5px;
  margin-bottom: 12px;

  font-weight: 700;
  font-size: 22px;
  line-height: 20px;
}

.animation-speed-option {
  width: 88px;
  height: 48px;
  margin-left: 5px;
  margin-right: 5px;

  font-weight: 700;
  font-size: 22px;
  line-height: 20px;
}
