.LoadingSpinner {
  justify-self: center;
  -webkit-animation: rotation 3s linear infinite;
  -moz-animation: rotation 3s linear infinite;
  animation: rotation 3s linear infinite;
}

@-moz-keyframes rotation {
  0% {
    transform: rotate(0deg) scale(1);
  }

  33% {
    transform: rotate(120deg) scale(0.8);
  }

  66% {
    transform: rotate(240deg) scale(1.2);
  }

  100% {
    transform: rotate(360deg) scale(1);
  }
}

@-webkit-keyframes rotation {
  0% {
    transform: rotate(0deg) scale(1);
  }

  33% {
    transform: rotate(120deg) scale(0.8);
  }

  66% {
    transform: rotate(240deg) scale(1.2);
  }

  100% {
    transform: rotate(360deg) scale(1);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg) scale(1);
  }

  33% {
    transform: rotate(120deg) scale(0.8);
  }

  66% {
    transform: rotate(240deg) scale(1.2);
  }

  100% {
    transform: rotate(360deg) scale(1);
  }
}
