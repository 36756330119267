.element-selector {
  width: 100%;
  min-height: 50vh;
  padding: 12px;
  background-color: black;
  border-radius: 10px;
  cursor: pointer;

  font-family: 'League Spartan';
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 24px;
  text-align: left;

  color: #f1ede3;
}

.selection-element,
.selected-element {
  padding: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  font-weight: 400;
  font-size: 26px;
  line-height: 24px;
  color: #f1ede3;
}

.selected-element {
  background: #5591ff;
  border-radius: 4px;
}
