.figma-plugin-button {
    margin-right: 24px;

    background: #5B9BFF;
    color: #FFFFFF;
    border-radius: 9px;
    border: none;

    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 13px;
    text-align: center;

    padding-left: 14px;
    padding-right: 14px;
    padding-top: 10px;
    padding-bottom: 10px;

    cursor: pointer;
}
