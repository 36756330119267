@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@400;600;700&display=swap');

@font-face {
  font-family: Albra;
  src: url('./fonts/Albra\ Text\ Regular.otf');
}

* {
  box-sizing: border-box;
}

.h2 {
  font-family: 'League Spartan';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 20px;

  color: #d9d9d9;
}

.fidget-app {
  display: flex;
  justify-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #222222;
  color: #f1ede3;
  font-family: 'League Spartan', sans-serif;
  overflow-x: hidden;
  flex-direction: column;
  align-items: center;
}

.header {
  width: 100%;
  height: 64px;
  padding-top: 8px;
  padding-bottom: 8px;

  background-color: black;
}

.header-content {
  max-width: 1200px;

  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;

  font-family: 'Albra';
  font-style: normal;
  font-weight: 700;
  font-size: 30.9565px;
  line-height: 120%;

  color: #f1ede3;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo-image {
  height: 40px;
  margin: 4px;
}

.download-button-symbol {
  cursor: pointer;
  margin-right: 24px;
}

.download-dialog-container {
  box-sizing: border-box;
  padding: 20px;

  background: #000000;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  display: grid;

  font-family: 'League Spartan';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 20px;
}

.body-container {
  max-width: 1200px;
  display: grid;
  grid-template-columns: 25% 35% 40%;
}

.element-selector-container,
.preview-container,
.animation-selector-container {
  height: fit-content;
  margin-top: 20px;
  margin-bottom: 10px;
}

.element-selector-container {
  width: 90%;
  text-align: center;
}

.inputLabel {
  font-style: normal;
  align-self: baseline;
  font-weight: 600;
  font-size: 16px;
  line-height: 15px;
  color: #e7e7e7;
  margin-top: 30px;
  margin-bottom: 14px;
}

.emailInput {
  height: 62px;
  width: 100%;

  color: rgba(241, 237, 227, 1);
  text-align: center;
  font-family: 'League Spartan';
  font-size: 16pt;
  font-weight: 400;
  text-decoration: underline;

  background: rgba(216, 216, 216, 0.1);
  border: 2px solid rgba(241, 237, 227, 0.3);
  border-radius: 8px;
}

.emailInput::placeholder {
  color: rgba(241, 237, 227);
  opacity: 0.6;
}
