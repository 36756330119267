.unsupported-browser-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    max-width: 812px;
}

.fidget-logo {
    width: 170px;
    margin: 55px;
}

.unsupported-browser-title {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 62px;
    line-height: 57px;

    text-align: center;

    color: #E3E3E3;
    margin-bottom: 25px;
}

.unsupported-browser-content {
    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;

    text-align: center;

    color: #FFFFFF;
}

.copy-page-link-button {
    background: #5B9BFF;
    color: #FFFFFF;
    border-radius: 9px;
    border: none;

    width: 320px;
    height: 62px;

    font-family: 'League Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 24px;
    text-align: center;

    cursor: pointer;

    margin-top: 25px;
}
